'use client'

import { HeaderProps, Page } from '@/util/payload/types'
import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { FunctionComponent, useEffect, useState } from 'react'
import { ActionBlock } from '../Blocks/ActionBlock/ActionBlock'
import { Brand } from '../Brand/Brand'
import ThemeToggler from './ThemeToggler'

export const Header: FunctionComponent<HeaderProps> = ({ navItems, actions }) => {
  const pages = navItems.map(({ page }) => page as Page)
  const [navigationOpen, setNavigationOpen] = useState(false)
  const [stickyMenu, setStickyMenu] = useState(false)
  const pathUrl = usePathname()

  // Sticky menu
  const handleStickyMenu = () => {
    if (window.scrollY >= 80) {
      setStickyMenu(true)
    } else {
      setStickyMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleStickyMenu)
  })

  return (
    <>
      <header className={clsx(
        'fixed left-0 top-0 z-999 w-full py-7 transition-[padding,box-shadow] bg-white dark:bg-black',
        stickyMenu && 'bg-white !py-4 shadow transition duration-100 dark:bg-black'
      )}>
        <div className='relative mx-auto max-w-c-1390 items-center justify-between px-4 md:px-8 xl:flex 2xl:px-0 gap-12'>
          <div className="flex items-center justify-between">
            <div className='grow xl:grow-0'>
              <Link href="/"><Brand /></Link>
            </div>
            <button onClick={() => setNavigationOpen(!navigationOpen)} aria-label="button for menu toggle" className="block xl:hidden">
              <span className="relative block h-5.5 w-5.5 cursor-pointer">
                <span className="du-block absolute right-0 h-full w-full">
                  <span className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${!navigationOpen && '!w-full delay-300'}`} />
                  <span className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${!navigationOpen && 'delay-400 !w-full'}`} />
                  <span className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${!navigationOpen && '!w-full delay-500'}`} />
                </span>
                <span className="du-block absolute right-0 h-full w-full rotate-45">
                  <span className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${!navigationOpen && '!h-0 delay-[0]'}`} />
                  <span className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${!navigationOpen && 'dealy-200 !h-0'}`} />
                </span>
              </span>
            </button>
          </div>

          {/* Nav Menu Start   */}
          <div className={clsx(
            'h-0 items-center justify-between invisible xl:visible xl:flex xl:h-auto w-full xl:w-auto grow',
            navigationOpen && 'navbar !visible h-auto max-h-[400px] rounded-md bg-white p-7.5 shadow-solid-5 dark:bg-blacksection xl:h-auto xl:p-0 xl:shadow-none xl:dark:bg-transparent'
          )}>
            <nav>
              <ul className="flex flex-col gap-5 xl:flex-row xl:items-center xl:gap-10">
                {pages.map((page) => (
                  <li key={page.id}>
                    <Link href={`${page.slug}`} className={clsx(
                      'text-gray',
                      (pathUrl === page.slug) && 'text-black dark:text-white'
                    )}>{page.title}</Link>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="ml-auto mt-7 flex items-center gap-6 xl:mt-0">
              {actions?.map((action) => (
                <ActionBlock key={action.id} {...action} blockType='Action' id={'id'} />
              ))}
              <ThemeToggler />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
