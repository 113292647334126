import { useTheme } from 'next-themes'
import { Icon } from '../Icon/Icon'

const ThemeToggler = () => {
  const { theme, setTheme } = useTheme()

  return (
    <button aria-label="theme toggler"
      className="bg-gray-2 dark:bg-dark-bg absolute right-17 mr-1.5 flex cursor-pointer items-center justify-center rounded-full text-black dark:text-white lg:static"
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
      <Icon icon='MoonIcon' width={21} height={21} className='dark:hidden' />
      <Icon icon='SunIcon' width={21} height={21} className='hidden dark:block' />
    </button>
  )
}

export default ThemeToggler
