import Image from 'next/image'
import { FunctionComponent } from 'react'

export const Brand: FunctionComponent = () => {
  return (
    <div className='flex gap-2 items-center'>
      <Image src='/images/logo-dark.svg' width={40} height={40} alt='Logo' className='hidden dark:block' />
      <Image src='/images/logo-light.svg' width={40} height={40} alt='Logo' className='dark:hidden' />
      <span className='text-lg text-black dark:text-white'>
        <span>Atomic</span>
        <span className='font-bold'>BI</span>
      </span>
    </div>
  )
}
