'use client'

import { Brand } from '@/components/Brand/Brand'
import { MotionDiv } from '@/components/MotionDiv/MotionDiv'
import { FooterProps } from '@/util/payload/types'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { FunctionComponent, Suspense } from 'react'
import { ActionBlock } from '../Blocks/ActionBlock/ActionBlock'

export const Footer: FunctionComponent<FooterProps> = ({ sections, ...rest }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <FooterContent sections={sections} {...rest} />
    </Suspense>
  )
}

const FooterContent: FunctionComponent<FooterProps> = ({ sections }) => {
  const searchParams = useSearchParams()
  const isDemo = searchParams.has('demo')
  const demoValue = searchParams.get('demo') ?? 'oekogekl'

  return (
    <>
      <footer className="border-t border-stroke bg-white dark:border-strokedark dark:bg-blacksection">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <div className="py-20 lg:py-25">
            <div className="flex flex-wrap gap-8 lg:justify-between lg:gap-0">
              <MotionDiv className="w-1/2 lg:w-1/4" preset='fade-up'>
                <Brand />
                <p className="mb-10 mt-5">Accelerating Business Productivity with AI</p>
                <p className="mb-1.5 text-sectiontitle uppercase tracking-[2px]">contact</p>
                <a href="mailto:team@atomic.bi" className="font-medium text-black dark:text-white">
                  team@atomic.bi
                </a>
              </MotionDiv>
              <div className="flex w-full flex-col gap-8 md:flex-row md:justify-between md:gap-0 lg:w-2/3 xl:w-7/12">

                {sections?.map((section) => (
                  <MotionDiv key={section.id} preset='fade-up' className='grow'>
                    <h2 className="mb-9 text-itemtitle2 font-medium text-black dark:text-white">{section.title}</h2>
                    <ul className='space-y-3'>
                      {section.content?.map((props) => (
                        <li key={props.id}><ActionBlock {...props} blockType='Action' id={props.title} /></li>
                      ))}
                    </ul>
                  </MotionDiv>
                ))}
              </div>
            </div>
          </div>
          {/* <!-- Footer Top --> */}

          {/* <!-- Footer Bottom --> */}
          <div className="flex flex-col flex-wrap items-center justify-center gap-5 border-t border-stroke py-7 dark:border-strokedark lg:flex-row lg:justify-between lg:gap-0">
            <MotionDiv preset='fade-up' className='grow'>
              <ul className="flex items-center gap-8">
                <li><Link href="/privacy-policy/" className="hover:text-primary">Privacy Policy</Link></li>
                <li><Link href="/terms-of-service/" className="hover:text-primary">Terms of Service</Link></li>
                {/* <li><a href="#" className="hover:text-primary">Support</a></li> */}
              </ul>
            </MotionDiv>
            <MotionDiv preset='fade-up' className='grow'>
              <p>&copy; {new Date().getFullYear()} AtomicBI. All rights reserved</p>
            </MotionDiv>
            <MotionDiv preset='fade-up' className='grow'>
              <ul className="flex items-center justify-end gap-5">
                <li>
                  <a href="https://www.linkedin.com/company/atomicbi/" target="_blank" title="LinkedIn" aria-label="social-link">
                    <svg
                      className="fill-[#D1D8E0] transition-all duration-300 hover:fill-primary"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_48_1505)">
                        <path
                          d="M6.94 5.00002C6.93974 5.53046 6.72877 6.03906 6.35351 6.41394C5.97825 6.78883 5.46944 6.99929 4.939 6.99902C4.40857 6.99876 3.89997 6.78779 3.52508 6.41253C3.1502 6.03727 2.93974 5.52846 2.94 4.99802C2.94027 4.46759 3.15124 3.95899 3.5265 3.5841C3.90176 3.20922 4.41057 2.99876 4.941 2.99902C5.47144 2.99929 5.98004 3.21026 6.35492 3.58552C6.72981 3.96078 6.94027 4.46959 6.94 5.00002ZM7 8.48002H3V21H7V8.48002ZM13.32 8.48002H9.34V21H13.28V14.43C13.28 10.77 18.05 10.43 18.05 14.43V21H22V13.07C22 6.90002 14.94 7.13002 13.28 10.16L13.32 8.48002Z"
                          fill=""
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_48_1505">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
              </ul>
            </MotionDiv>
          </div>
        </div>
      </footer>
      {isDemo && (
        <>
          <Script id="chat-widget" type="text/javascript">
            {`window.spaceId = "${demoValue}";`}
          </Script>
          <Script type="module" src="/assets/index.js" crossOrigin="anonymous"/>
        </>
      )}
    </>
  )
}